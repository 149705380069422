<script lang="ts" setup>
    import type { ProductLabel } from '~/graphql/generated';

    interface ProductLabelsProps {
        labels: ProductLabel[];
        variantLabels: ProductLabel[];
    }
    const props = defineProps<ProductLabelsProps>();

    const labelsProcessed = computed(() => [...props.variantLabels, ...props.labels].filter(({ label }) => label).slice(0, 2));
</script>
<template>
    <div class="mol-product-labels absolute flex flex-wrap items-start gap-1">
        <lazy-atm-product-label
            v-for="(label, index) in labelsProcessed"
            :key="`${label.code}${index}`"
            :label="label" />
    </div>
</template>
